import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

const PressReleasesSlug = ({ data }) => {
  const { markdownRemark: markup } = data
  const { image, name, link } = markup.frontmatter

  return (
    <Layout>
      <section className="press-releases-slug py-5 ">
        <div className="container pb-3">
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={name}
            className="rounded-start h-100 mb-5"
          />

          <div
            className="customContent"
            dangerouslySetInnerHTML={{ __html: markup.html }}
          />

          <h2>About Credence Analytics</h2>
          <p>
            Credence Analytics is an International software solution provider
            for over 25 years having marquee clients across Asia, Middle East &
            African markets. In its home market i.e. India, its clients
            comprises of leading Banks & Institutions like Reliance Nippon Asset
            Management (One of the Largest Asset Management Companies of Indian
            subcontinent), State Bank of India (Largest Bank in India), ICICI
            Bank (Largest Non-Government owned bank in India), SBI Life
            Insurance (Second largest life insurance company in India) and SBI
            Associate Banks (Largest Banking Group in India). Globally Credence
            Analytics has presence in South East Asia, Middle East, and African
            Countries with clients in Nigeria, Vietnam, Oman, UAE, Sri Lanka and
            Philippines. The strength of Credence Analytics is its strong domain
            expertise in the areas of Treasury, Investment Management and Risk
            Management. The Firm Strongly believes that its role goes beyond
            providing software solution by actively implementing the solution
            and advising the customer on how to use the software to the fullest
            extent.
          </p>
          <h1>For more information contact:</h1>

          <p>
            <strong>Akash Anand | Chief Marketing & Sales Officer</strong>
            <br />
            Credence Analytics (I) Private Limited
            <br />
            <a href="mailto:media@credenceanalytics.com">
              media@credenceanalytics.com
            </a>
          </p>

          <p>
            <strong>Disclaimer:</strong>
          </p>
          <p>
            Except for the historical information contained herein, statements
            in this release, which contain words or phrases such as "will",
            "would", "expect", "believe", and similar expressions or variations
            of such expressions may constitute "forward-looking statements".
            These forward-looking statements involve a number of risks,
            uncertainties and other factors that could cause actual results to
            differ materially from those suggested by the forward-looking
            statements. These risks and uncertainties include, but are not
            limited to, the performance of the Indian economy and of the
            economies of our principal international markets, the performance of
            the industry sectors in which our clients are based, the performance
            of the information technology industry sector world-wide,
            competition, our ability to obtain statutory and regulatory
            approvals and to successfully implement our strategy, future levels
            of our growth and expansion in business, technological
            implementation, changes, advancements, and redundancies, the actual
            demand for software products and services, or the future potential
            or feasibility thereof, changes in revenue, income or cash flows,
            our market preferences and our exposure to market risks, as well as
            other risks. Credence Analytics undertakes no obligation to update
            forward-looking statements to reflect events or circumstances after
            the date thereof.
          </p>

          {link && (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary"
            >
              Download
            </a>
          )}
        </div>
      </section>
    </Layout>
  )
}

export const PressSlugQuery = graphql`
  query PressSlugQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        name
        link
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, height: 150, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`

export default PressReleasesSlug
